import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import AppointmentModal from './appointmentModal';
import BlockOffModal from './blockOffModal';
import ScheduleTemplater from './scheduleTemplater';

import Icon from '../common/icon';
import Navbar from '../navbar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../styles/components/schedule.scss';

function Schedule() {
  const currentDate = localStorage.getItem('calendarDate');
  const locationId = localStorage.getItem('locationId');
  const isLoveland = Number(locationId) === 1;

  const [appointments, setAppointments] = useState([]);
  const [blockOffs, setBlockOffs] = useState([]);
  const [scheduleColumns, setScheduleColumns] = useState([]);
  const [doctorSchedule, setDoctorSchedule] = useState([]);
  const [date, setDate] = useState(currentDate || moment().format('YYYY-MM-DD'));

  const [showScheduleTemplaterModal, setShowScheduleTemplaterModal] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showBlockOffModal, setShowBlockOffModal] = useState(false);
  const [selectedAppt, setSelectedAppt] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const localizer = momentLocalizer(moment);

  useEffect(() => {
    const getScheduleData = async () => {
      try {
        setIsLoading(true);

        const apptResponse = await http.get(
          `/schedule/getDailyAppointments?date=${date}&location_id=${locationId}`
        );
        const appts = apptResponse.data.map(t => ({
          ...t,
          title: (
            <p style={{ fontFamily: 'unset' }}>
              <Icon name={t.icon_name} tooltipText={t.appointment_status} />
              <strong style={{ color: 'darkRed' }}>
                {t.name} ({t.first_name} {t.last_name})
              </strong>{' '}
              {t.complaint}
            </p>
          ),
          start: new Date(t.start),
          end: new Date(t.end),
          resourceId: String(t.schedule_column_id),
        }));
        setAppointments(appts);

        const blockOffResponse = await http.get(
          `/schedule/getDailyBlockOffs?date=${date}&location_id=${locationId}`
        );
        const blockOffs = blockOffResponse.data.map(t => ({
          ...t,
          title: (
            <p style={{ fontFamily: 'unset' }}>
              <strong style={{ color: '#313030' }}>{t.label || t.block_off_type}</strong>
              {t.notes && (
                <React.Fragment>
                  <br />
                  {t.notes}
                </React.Fragment>
              )}
            </p>
          ),
          start: new Date(t.start),
          end: new Date(t.end),
          resourceId: String(t.schedule_column_id),
          isBlockOff: true,
        }));
        setBlockOffs(blockOffs);

        const scheduleColumnResponse = await http.get(
          `/schedule/getColumns?location_id=${locationId}`
        );
        setScheduleColumns(scheduleColumnResponse.data);

        const doctorScheduleResponse = await http.get(
          `/schedule/getDoctorSchedule?date=${date}&location_id=${locationId}`
        );
        setDoctorSchedule(doctorScheduleResponse.data);

        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setIsLoading(false);
      }
    };

    getScheduleData();
  }, [locationId, date]);

  const resources = scheduleColumns.map(t => ({
    resourceId: t.id,
    resourceTitle: t.name,
  }));

  const backgroundEvents = doctorSchedule.map(t => ({
    title: '',
    start: new Date(t.start),
    end: new Date(t.end),
    resourceId: String(t.schedule_column_id),
  }));

  const formats = {
    eventTimeRangeFormat: () => {},
  };

  return (
    <div className="schedule h-100">
      <Navbar selectedTab="schedule" />
      <div className="p-3 h-100">
        {isLoading && <i className="flex-centered h-100 fa fa-circle-notch fa-spin fa-2x subtle" />}
        {!isLoading && errorMessage && <p className="error flex-centered mt-5">{errorMessage}</p>}
        {!isLoading && !errorMessage && (
          <React.Fragment>
            {isLoveland && (
              <button
                className="mb-2 btn-text-primary align-self-end"
                onClick={() => setShowScheduleTemplaterModal(true)}
              >
                Schedule Templater
              </button>
            )}
            <Link to="/cancellation-list">
              <button className="btn-text-primary align-self-end ">Cancellation List</button>
            </Link>
            <Calendar
              components={{ toolbar: CustomToolbar }}
              localizer={localizer}
              formats={formats}
              events={[...appointments, ...blockOffs]}
              backgroundEvents={backgroundEvents}
              defaultView={Views.DAY}
              views={[Views.DAY]}
              step={15}
              timeslots={4}
              selectable
              date={date}
              onNavigate={t => {
                const newDate = moment(t).format('YYYY-MM-DD');
                localStorage.setItem('calendarDate', newDate);
                setDate(newDate);
              }}
              onSelectSlot={slot => {
                if (isLoveland) return;
                setSelectedAppt(slot);
                setShowAppointmentModal(true);
              }}
              onSelectEvent={appt => {
                setSelectedAppt(appt);
                if (appt.isBlockOff) {
                  setShowBlockOffModal(true);
                } else {
                  setShowAppointmentModal(true);
                }
              }}
              tooltipAccessor={() => ''}
              min={new Date(2014, 0, 0, 7, 0, 0)}
              max={new Date(2100, 0, 0, 19, 0, 0)}
              startAccessor="start"
              endAccessor="end"
              eventPropGetter={event => {
                return {
                  style: {
                    backgroundColor:
                      event.appointment_status === 'Cancelled' ? 'lightgray' : event.color,
                  },
                };
              }}
              resources={resources}
              resourceIdAccessor="resourceId"
              resourceTitleAccessor="resourceTitle"
            />
          </React.Fragment>
        )}
      </div>
      {showScheduleTemplaterModal && (
        <ScheduleTemplater handleClose={() => setShowScheduleTemplaterModal(false)} />
      )}
      {showAppointmentModal && (
        <AppointmentModal appt={selectedAppt} handleClose={() => setShowAppointmentModal(false)} />
      )}
      {showBlockOffModal && (
        <BlockOffModal blockOff={selectedAppt} handleClose={() => setShowBlockOffModal(false)} />
      )}
    </div>
  );
}

function CustomToolbar({ date, onNavigate }) {
  const goToBack = () => {
    onNavigate('PREV');
  };

  const goToNext = () => {
    onNavigate('NEXT');
  };

  const goToToday = () => {
    onNavigate('TODAY');
  };

  return (
    <div className="rbc-toolbar p-3">
      <div className="rbc-toolbar-label">{moment(date).format('dddd MMMM DD, YYYY')}</div>
      <div className="rbc-btn-group me-3">
        <button className="roboto font-14" onClick={goToToday}>
          Today
        </button>
        <button className="roboto font-18" onClick={goToBack}>
          &lt;
        </button>
        <button className="roboto font-18" onClick={goToNext}>
          &gt;
        </button>
      </div>
      <input
        className="form-control w-auto border p-2 background-white"
        type="date"
        value={date}
        onChange={t => onNavigate('DATE', t.target.value)}
      />
    </div>
  );
}

export default Schedule;
