import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ContractPreview from './contractPreview';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';

import { formatCurrency } from '../../util';

import Constants from '../../constants';

function InitiatePaymentPlan({ clientId, handleClose }) {
  const locationId = localStorage.getItem('locationId');
  const userIsManager =
    Number(JSON.parse(localStorage.getItem('loggedIn')).role_id) === Constants.MANAGER;

  const [startDate, setStartDate] = useState(moment().tz('America/Denver').format('YYYY-MM-DD'));
  const [amount, setAmount] = useState(0);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [paymentOption, setPaymentOption] = useState('');
  const [previewInfo, setPreviewInfo] = useState({});

  const [showAllPaymentOptions, setShowAllPaymentOptions] = useState(false);
  const [currentStep, setCurrentStep] = useState('planDetails');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getBalance = async () => {
      const response = await http.get(`/client/getBalance?client_id=${clientId}`);
      setAmount(Number(response.data.balance) / 100);
    };
    getBalance();
  }, [clientId]);

  useEffect(() => {
    const getPaymentOptions = async () => {
      const response = await http.get(
        `/paymentPlan/getPaymentOptions?amount=${Math.round(amount * 100)}&client_id=${clientId}`
      );
      setPaymentOptions(response.data.options);
    };
    getPaymentOptions();
  }, [amount, clientId]);

  const handleNext = async nextStep => {
    const response = await http.post('/paymentPlan/initiate/preview', getParams());
    setPreviewInfo(response.data);
    setCurrentStep(nextStep);
    setErrorMessage('');
  };

  const handleBack = previousStep => {
    setErrorMessage('');
    setCurrentStep(previousStep);
  };

  const handleSubmit = async () => {
    setErrorMessage('');
    await http.post('/paymentPlan/initiate', getParams());
    navigate(0);
  };

  const getParams = () => {
    return {
      clientId,
      locationId,
      startDate,
      amount: Math.round(amount * 100),
      paymentOption,
    };
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <React.Fragment>
          {errorMessage && <p className="error input-width">{errorMessage}</p>}

          {currentStep === 'planDetails' && (
            <React.Fragment>
              <h2 className="mb-4">Plan Details:</h2>
              <Input
                type="date"
                name="startDate"
                label="Contract Start Date"
                value={startDate}
                onChange={setStartDate}
              />

              <Input
                type="number"
                name="amount"
                label="Payment Plan Amount"
                value={amount}
                onChange={setAmount}
              />

              {amount > 0 && (
                <React.Fragment>
                  <p className="sura input-width">
                    Payment options include total of {formatCurrency(Math.round(amount * 100))} for
                    services + $20.00 Payment Plan Set-up Charge
                  </p>

                  <p className="sura bold font-16 mt-3 mb-1">Payment Options:</p>

                  {userIsManager && (
                    <InputCheckbox
                      className="my-2"
                      name="showAllPaymentOptions"
                      label="Show All Payment Options"
                      checked={showAllPaymentOptions}
                      onChange={setShowAllPaymentOptions}
                    />
                  )}

                  {paymentOptions
                    .filter(t => showAllPaymentOptions || !t.managerOnly)
                    .map((t, i) => (
                      <div className="mt-2" key={i}>
                        <div className="radio-line align-items-start mb-2">
                          <input
                            className="mt-2"
                            type="radio"
                            id={`option_${i}`}
                            name="paymentPlanOption"
                            checked={paymentOption === t.paymentOption}
                            value={t.paymentOption}
                            onChange={({ target }) => setPaymentOption(target.value)}
                          />
                          <label className="fw-bolder news-cycle font-16" htmlFor={`option_${i}`}>
                            <p>{t.termsTitle}</p>
                            <p>
                              <span className="attention">
                                {formatCurrency(t.monthlyPayment + t.downPayment + t.offset + 2000)}
                              </span>{' '}
                              due today plus
                            </p>
                            <p>
                              <span className="orange">{formatCurrency(t.monthlyPayment)}</span> for{' '}
                              {t.duration - 1} months
                            </p>
                          </label>
                        </div>
                      </div>
                    ))}
                </React.Fragment>
              )}

              <ButtonPanel
                primaryButtonText="Next"
                handleCancel={handleClose}
                handleSubmit={() => handleNext('preview')}
                handleErrorMessage={setErrorMessage}
                disabled={!amount || !paymentOption}
              />
            </React.Fragment>
          )}

          {currentStep === 'preview' && (
            <React.Fragment>
              <h2 className="mb-4">Preview</h2>
              <ContractPreview previewInfo={previewInfo} isHealthPlan={false} />
              <ButtonPanel
                primaryButtonText="Initiate Payment Plan"
                handleCancel={handleClose}
                handleBack={() => handleBack('planDetails')}
                handleSubmit={handleSubmit}
                handleErrorMessage={setErrorMessage}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </form>
    </Modal>
  );
}

export default InitiatePaymentPlan;
