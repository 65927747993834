import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import InputSelect from '../common/inputSelect';

function SurgeryCancellationModal({ surgery, handleClose }) {
  const [reason, setReason] = useState(1);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    await http.delete(`/surgery/cancelSurgery?appointment_id=${surgery.id}&reason=${reason}`);
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="sura font-16 mb-4">
          Are you sure you would like to cancel the surgery for {surgery.name} on{' '}
          {moment(surgery.start).tz('America/Denver').format('MM/DD/YY')} with Dr. {surgery.doctor}?
          This action cannot be undone
        </p>
        <InputSelect
          name="reason"
          value={reason}
          label="Reason"
          optionConfig={[
            { value: 1, name: 'Cancelled by Client' },
            { value: 2, name: 'Cancelled by Clinic' },
            { value: 3, name: 'Created in Error' },
            { value: 4, name: 'No Show' },
          ]}
          onChange={setReason}
        />
        <ButtonPanel
          primaryButtonText="Confirm"
          secondaryButtonText="Back"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
        />
      </form>
    </Modal>
  );
}

export default SurgeryCancellationModal;
