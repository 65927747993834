import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';

import http from '../services/httpService';

import Icon from './common/icon';
import Input from './common/input';
import InputSelect from './common/inputSelect';
import Page from './common/page';
import PasswordRequirements from './common/passwordRequirements';

import Constants from '../constants';

import '../styles/components/user-details.scss';

function StaffDetails({ staffId, isProfile, handleClose, isPage }) {
  const [staff, setStaff] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [editingItem, setEditingItem] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getStaff = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await http.get(`/staff/getById?id=${staffId}`);
      setStaff(response.data);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  }, [staffId]);

  useEffect(() => {
    getStaff();
  }, [getStaff]);

  const handleEditChange = item => {
    setEditingItem(item ? item : '');
    setErrorMessage('');
    setFirstName(item === 'firstName' ? staff.first_name : '');
    setLastName(item === 'lastName' ? staff.last_name : '');
    setUsername(item === 'username' ? staff.username : '');
    setPassword('');
    setConfirmPassword('');
    setEmail(item === 'email' ? staff.email : '');
    setRole(item === 'role' ? staff.role_id : Constants.STAFF);
  };

  const handleSubmit = async (params, endPoint) => {
    if (isLoading) return;
    try {
      setIsLoading(true);

      params.id = staffId;
      await http.post(`staff/${endPoint}`, params);

      await getStaff();
      handleEditChange();
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.response.data.message);
    }
  };

  const content = (
    <form className="user-details">
      {isLoading && (
        <i className="flex-centered w-100 p-4 fa fa-circle-notch fa-spin fa-2x subtle" />
      )}
      {!isLoading && (
        <React.Fragment>
          <p className="error">{errorMessage}</p>
          <Item
            name="firstName"
            value={firstName}
            defaultValue={staff.first_name}
            label="First Name"
            editMode={editingItem === 'firstName'}
            editDisabled={isProfile}
            handleChange={setFirstName}
            handleEditChange={handleEditChange}
            handleSubmit={() => handleSubmit({ firstName }, 'updateFirstName')}
          />
          <Item
            name="lastName"
            value={lastName}
            defaultValue={staff.last_name}
            label="Last Name"
            editMode={editingItem === 'lastName'}
            editDisabled={isProfile}
            handleChange={setLastName}
            handleEditChange={handleEditChange}
            handleSubmit={() => handleSubmit({ lastName }, 'updateLastName')}
          />
          <Item
            name="username"
            value={username}
            defaultValue={staff.username}
            label="Username"
            editMode={editingItem === 'username'}
            editDisabled={!isProfile}
            handleChange={setUsername}
            handleEditChange={handleEditChange}
            handleSubmit={() => handleSubmit({ username }, 'updateUsername')}
          />
          {isProfile && (
            <div className={'item' + (editingItem === 'password' ? ' edit-mode' : '')}>
              <label>Password</label>
              {editingItem !== 'password' && (
                <React.Fragment>
                  <span>---****</span>
                  <button
                    type="button"
                    className="btn-text-primary"
                    onClick={() => handleEditChange('password')}
                  >
                    <Icon name="edit" />
                  </button>
                </React.Fragment>
              )}
              {editingItem === 'password' && (
                <div className="d-flex flex-column align-items-start">
                  <Input
                    className="m-0"
                    type="password"
                    name="password"
                    value={password}
                    label="New Password"
                    onChange={setPassword}
                  />
                  <Input
                    className="m-0 mt-3"
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    label="Retype New Password"
                    onChange={setConfirmPassword}
                  />
                  <PasswordRequirements />
                  <Buttons
                    handleEditChange={handleEditChange}
                    handleSubmit={() => {
                      if (password !== confirmPassword) {
                        setErrorMessage('The provided passwords do not match. Please try again.');
                        return;
                      }
                      handleSubmit({ password }, 'updatePassword');
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <Item
            name="email"
            value={email}
            defaultValue={staff.email}
            label="Email"
            editMode={editingItem === 'email'}
            handleChange={setEmail}
            handleEditChange={handleEditChange}
            handleSubmit={() => handleSubmit({ email }, 'updateEmail')}
          />
          <div className={'item' + (editingItem === 'role' ? ' edit-mode' : '')}>
            <label>Role</label>
            {editingItem !== 'role' && (
              <React.Fragment>
                <span>{staff.role}</span>
                {!isProfile && (
                  <button
                    type="button"
                    className="btn-text-primary"
                    onClick={() => handleEditChange('role')}
                  >
                    <Icon name="edit" />
                  </button>
                )}
              </React.Fragment>
            )}
            {editingItem === 'role' && (
              <div className="d-flex flex-column align-items-start">
                <InputSelect
                  name="role"
                  value={role}
                  label="Role"
                  optionConfig={[
                    { name: 'Staff', value: Constants.STAFF },
                    { name: 'Manager', value: Constants.MANAGER },
                  ]}
                  onChange={setRole}
                />
                <Buttons
                  handleEditChange={handleEditChange}
                  handleSubmit={() => handleSubmit({ roleId: role }, 'updateRole')}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </form>
  );

  if (isPage) {
    return (
      <Page selectedTab="account">
        <div className="background-gray rounded-bottom box-shadow p-3">{content}</div>
      </Page>
    );
  }
  return (
    <Modal show={true} onHide={handleClose} centered>
      <div className="d-flex w-auto align-items-end mt-4 mb-2 me-3 p-0">
        <button className="h-auto" onClick={handleClose}>
          <Icon name="close" className="m-0" />
        </button>
      </div>
      {content}
    </Modal>
  );
}

export default StaffDetails;

function Item({
  name,
  value,
  defaultValue,
  label,
  editMode,
  editDisabled,
  handleChange,
  handleEditChange,
  handleSubmit,
}) {
  return (
    <div className={'item' + (editMode ? ' edit-mode' : '')}>
      <label>{label}</label>
      {!editMode && (
        <React.Fragment>
          <span>{defaultValue}</span>
          {!editDisabled && (
            <button
              type="button"
              className="btn-text-primary"
              onClick={() => handleEditChange(name)}
            >
              <Icon name="edit" />
            </button>
          )}
        </React.Fragment>
      )}
      {editMode && (
        <div className="d-flex flex-column align-items-start">
          <Input
            className="m-0"
            name={name}
            value={value}
            label={`New ${label}`}
            onChange={handleChange}
          />
          <Buttons handleEditChange={handleEditChange} handleSubmit={handleSubmit} />
        </div>
      )}
    </div>
  );
}

function Buttons({ handleEditChange, handleSubmit }) {
  return (
    <div className="d-flex justify-content-end input-width mt-3">
      <button type="button" className="btn-text-secondary me-3" onClick={() => handleEditChange()}>
        Cancel
      </button>
      <button
        type="button"
        className="h-auto p-2 btn-rounded-primary"
        onClick={() => handleSubmit()}
      >
        Save
      </button>
    </div>
  );
}
