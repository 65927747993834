import React, { useState, useEffect } from 'react';

import http from '../../services/httpService';

import CreateStaff from './createStaff';
import DeleteStaff from './deleteStaff';

import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import { Mobile, NotMobile } from '../common/responsive';

import StaffDetails from '../staffDetails';

function Staff() {
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState('');
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showCreateStaff, setShowCreateStaff] = useState(false);
  const [showDeleteStaff, setShowDeleteStaff] = useState(false);
  const [showRegistrationEmailConfirmation, setShowRegistrationEmailConfirmation] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getStaff = async () => {
      try {
        setIsLoading(true);
        const response = await http.get('/staff/getActive');
        setStaff(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getStaff();
  }, []);

  const handleSendRegistrationEmail = async () => {
    await http.post(`/staff/sendRegistrationEmail?staff_id=${selectedStaff.id}`);
    setShowRegistrationEmailConfirmation(false);
  };

  const registrationEmailButton = staffMember => {
    return (
      <button
        className="btn-text-error sura font-18 fw-normal"
        onClick={() => {
          setSelectedStaff(staffMember);
          setShowRegistrationEmailConfirmation(true);
        }}
      >
        <Icon name="on_hold" tooltipText="Account has not been registered" />
        <span className="font-14">Send Registration Email</span>
      </button>
    );
  };

  const editButton = staffMember => {
    return (
      <button
        className="btn-text-primary sura font-18 fw-normal"
        onClick={() => {
          setShowUserDetails(true);
          setSelectedStaff(staffMember);
        }}
      >
        <Icon name="edit" />
      </button>
    );
  };

  const deleteButton = staffMember => {
    return (
      <button
        className="btn-text-error sura font-18 fw-normal"
        onClick={() => {
          setShowDeleteStaff(true);
          setSelectedStaff(staffMember);
        }}
      >
        <Icon name="delete" tooltipText="Deactivate" />
      </button>
    );
  };

  const newButton = (
    <button type="button" className="btn-rounded-primary" onClick={() => setShowCreateStaff(true)}>
      <Icon name="add" />
      New Staff Member
    </button>
  );

  return (
    <React.Fragment>
      <h1 className="pb-4">Staff Members</h1>
      {isLoading && <i className="flex-centered m-5 fa fa-circle-notch fa-spin fa-2x subtle" />}
      {!isLoading && errorMessage && <p className="error">{errorMessage}</p>}
      {!isLoading && !errorMessage && (
        <React.Fragment>
          <Mobile>
            <React.Fragment>
              {newButton}
              {staff.map((t, i) => (
                <div key={i} className="d-flex flex-column border rounded p-3 mt-3">
                  <div className="flex-row-aligned justify-content-between">
                    <h2>
                      {t.last_name}, {t.first_name}
                    </h2>
                    <div className="flex-row-aligned">
                      <div className="me-2">{editButton(t)}</div>
                      {deleteButton(t)}
                    </div>
                  </div>
                  {t.username && (
                    <span className="mt-2 ps-3">
                      <span className="sura me-2">Username:</span>
                      {t.username}
                    </span>
                  )}
                  {!t.username && <span className="mt-2 ps-3">{registrationEmailButton(t)}</span>}
                  <span className="ps-3">
                    <span className="sura me-2">Email:</span>
                    {t.email}
                  </span>
                  <span className="ps-3">
                    <span className="sura me-2">Role:</span>
                    {t.role}
                  </span>
                </div>
              ))}
            </React.Fragment>
          </Mobile>
          <NotMobile>
            <React.Fragment>
              <div className="w-100 mb-4">
                <table className="w-100">
                  <thead className="border-bottom border-top">
                    <tr>
                      <th className="py-3 px-2">Name</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {staff.map((t, i) => (
                      <tr key={i} className={i % 2 === 0 ? `background-gray` : ''}>
                        <td className="p-2">
                          {t.last_name}, {t.first_name}
                        </td>
                        <td>{t.username ? t.username : registrationEmailButton(t)}</td>
                        <td>{t.email}</td>
                        <td>{t.role}</td>
                        <td>{editButton(t)}</td>
                        <td>{deleteButton(t)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {newButton}
            </React.Fragment>
          </NotMobile>
        </React.Fragment>
      )}

      {showUserDetails && (
        <StaffDetails
          staffId={selectedStaff.id}
          userIsAdmin={true}
          handleClose={() => setShowUserDetails(false)}
        />
      )}
      {showCreateStaff && <CreateStaff handleClose={() => setShowCreateStaff(false)} />}
      {showDeleteStaff && (
        <DeleteStaff staff={selectedStaff} handleClose={() => setShowDeleteStaff(false)} />
      )}
      {showRegistrationEmailConfirmation && (
        <Confirmation
          message="Are you sure you would like to send registration email to staff member? Email will be valid for 3 hours."
          handleConfirm={handleSendRegistrationEmail}
          handleClose={() => setShowRegistrationEmailConfirmation(false)}
        />
      )}
    </React.Fragment>
  );
}

export default Staff;
