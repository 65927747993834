import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/global/react-popper-tooltip.css';
import { usePopperTooltip } from 'react-popper-tooltip';

import http from '../../services/httpService';

import CheckOutModal from '../admin/checkOutModal';
import ClientModal from './clientModal';

import Confirmation from '../common/confirmation';
import Icon from '../common/icon';

import Constants from '../../constants';

function AdminClientOptions({ client }) {
  const [showModal, setShowModal] = useState(null);
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const userIsManager =
    Number(JSON.parse(localStorage.getItem('loggedIn')).role_id) === Constants.MANAGER;

  const handleRegistrationEmail = async () => {
    await http.post(`/client/sendRegistrationEmail?client_id=${client.id}`);
    navigate(0);
  };

  const handleSendUsernameEmail = async () => {
    await http.post(`/client/sendUsernameEmail?client_id=${client.id}`);
    navigate(0);
  };

  const handleAllowPaymentPlans = async () => {
    await http.post(`/client/allowPaymentPlans?client_id=${client.id}`);
    navigate(0);
  };

  const handleDisallowPaymentPlans = async () => {
    await http.post(`/client/disallowPaymentPlans?client_id=${client.id}`);
    navigate(0);
  };

  const handleDeleteClient = async () => {
    await http.delete(`/client/delete?client_id=${client.id}`);
    navigate('/');
    navigate(0);
  };

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    trigger: 'click',
    onVisibleChange: () => setVisible(!visible),
  });

  const toggleModal = modalToDisplay => {
    setVisible(false);
    setShowModal(modalToDisplay);
  };

  const dropdownOptions = [];

  if (client.openAppointments && client.openAppointments.length > 0) {
    dropdownOptions.push({
      text: 'Check Out',
      handleClick: () => toggleModal('check_out'),
    });
  }

  dropdownOptions.push({ text: 'Edit Client', handleClick: () => toggleModal('edit_client') });

  if (client.email && !client.username) {
    dropdownOptions.push({
      text: 'Send Registration Email',
      handleClick: () => toggleModal('registration_email'),
    });
  }

  if (client.email && client.username) {
    dropdownOptions.push({
      text: 'Email Username to Client',
      handleClick: () => toggleModal('email_username'),
    });
  }

  if (userIsManager) {
    if (client.no_payment_plans === 'Y') {
      dropdownOptions.push({
        text: 'Allow Payment Plans',
        handleClick: () => toggleModal('allow_payment_plans'),
      });
    } else {
      dropdownOptions.push({
        text: 'Disallow Payment Plans',
        handleClick: () => toggleModal('disallow_payment_plans'),
      });
    }

    if (client.pets.length === 0) {
      dropdownOptions.push({
        text: 'Delete Client',
        handleClick: () => toggleModal('delete_client'),
      });
    }
  }

  if (dropdownOptions.length === 0) {
    return null;
  }

  return (
    <div ref={setTriggerRef}>
      <Icon name="options_dots" className="me-0" tooltipText="Admin Options" />
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container options' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <div className="d-flex flex-column">
            {dropdownOptions.map((t, i) => (
              <button key={i} type="button" onClick={t.handleClick}>
                {t.text}
              </button>
            ))}
          </div>
        </div>
      )}
      {showModal === 'check_out' && (
        <CheckOutModal clientId={client.id} handleClose={toggleModal} />
      )}
      {showModal === 'edit_client' && <ClientModal client={client} handleClose={toggleModal} />}
      {showModal === 'registration_email' && (
        <Confirmation
          message="Are you sure you would like to send registration email to client? Email will be valid for 3 hours."
          handleConfirm={handleRegistrationEmail}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'email_username' && (
        <Confirmation
          message="Are you sure you would like to send an email to the client containing their username for the Client Portal"
          handleConfirm={handleSendUsernameEmail}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'allow_payment_plans' && (
        <Confirmation
          message="Are you sure you would like to make this client eligible for payment plans?"
          handleConfirm={handleAllowPaymentPlans}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'disallow_payment_plans' && (
        <Confirmation
          message="Are you sure you would like to make this client ineligible for payment plans?"
          handleConfirm={handleDisallowPaymentPlans}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'delete_client' && (
        <Confirmation
          message="Are you sure you would like to delete this client?"
          handleConfirm={handleDeleteClient}
          handleClose={toggleModal}
        />
      )}
    </div>
  );
}

export default AdminClientOptions;
