import React from 'react';

import DelinquencyReport from './delinquencyReport';
import Staff from './staff';

import Page from '../common/page';
import { Mobile, NotMobile } from '../common/responsive';

function Admin() {
  return (
    <Page selectedTab="admin">
      <div className="background-white box-shadow flex-grow-1">
        <Mobile>
          <div className="p-3">
            <Staff />
            <div className="mt-5">
              <DelinquencyReport />
            </div>
          </div>
        </Mobile>
        <NotMobile>
          <div className="p-5">
            <Staff />
            <div className="mt-5">
              <DelinquencyReport />
            </div>
          </div>
        </NotMobile>
      </div>
    </Page>
  );
}

export default Admin;
