import React, { useState } from 'react';
import moment from 'moment-timezone';

import http from '../../../services/httpService';

import Input from '../../common/input';

function ScheduleStats() {
  const [stats, setStats] = useState({});

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [doctorHours, setDoctorHours] = useState('');
  const [techHours, setTechHours] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      const response = await http.post(`/report/getScheduleStatsReport`, {
        startDate,
        endDate,
        doctorHours,
        techHours,
      });
      setStats(response.data);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className="d-flex">
        <Input
          className="me-2"
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <Input type="date" name="endDate" label="End Date" value={endDate} onChange={setEndDate} />
      </div>
      <Input
        name="doctorHours"
        label="Doctor Hours"
        value={doctorHours}
        onChange={setDoctorHours}
      />
      <Input name="techHours" label="Tech Hours" value={techHours} onChange={setTechHours} />
      <button
        className="my-3 me-5 btn-filled-primary input-width"
        onClick={handleSubmit}
        disabled={isLoading || !doctorHours || !techHours}
      >
        Run
      </button>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {isLoading && (
        <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
      )}
      {!isLoading && (
        <React.Fragment>
          <h2>Current Allocations:</h2>
          <div className="ms-3 mb-3">
            <h3>Surgery Hours: {stats.surgeryHours}</h3>
            <h3>Admit Hours: {stats.admitHours}</h3>
            <h3>Admit Blocks: {stats.admitBlocks}</h3>
            <h3>Priority Blocks: {stats.priorityBlocks}</h3>
          </div>
          <h2>Targets from Calculator:</h2>
          <div className="ms-3 mb-3">
            <h3>Total: {stats.totalAppts}</h3>
            <h3>Surgery: {stats.surgeryAppts}</h3>
            <h3>Room: {stats.roomAppts}</h3>
            <h3>Admit: {stats.admitAppts}</h3>
            <h3>Priority: {stats.priorityAppts}</h3>
            <h3>TA: {stats.techAppts}</h3>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ScheduleStats;
