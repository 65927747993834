import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Input from '../common/input';
import InputSelect from '../common/inputSelect';

import Constants from '../../constants';

function CreateStaff({ handleClose }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState(Constants.STAFF);

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const staff = { firstName, lastName, email, roleId };
    await http.post('/staff/create', staff);
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error input-width">{errorMessage}</p>}
        <Input
          name="firstName"
          value={firstName}
          label="First Name"
          onChange={setFirstName}
          autoFocus={true}
        />
        <Input name="lastName" value={lastName} label="Last Name" onChange={setLastName} />
        <Input name="email" value={email} label="Email" onChange={setEmail} />
        <InputSelect
          name="roleId"
          value={roleId}
          label="Role"
          optionConfig={[
            { name: 'Staff', value: Constants.STAFF },
            { name: 'Manager', value: Constants.MANAGER },
          ]}
          onChange={setRoleId}
        />
        <ButtonPanel
          primaryButtonText="Create"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          handleErrorMessage={setErrorMessage}
        />
      </form>
    </Modal>
  );
}

export default CreateStaff;
