import React, { useState, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment-timezone';

import Icon from '../common/icon';
import Input from '../common/input';
import Page from '../common/page';

import http from '../../services/httpService';

import { formatCurrency } from '../../util';

function AdminDashboard() {
  const [healthPlanStats, setHealthPlanStats] = useState({});
  const [paymentPlanStats, setPaymentPlanStats] = useState({});

  const [showHealthPlanStats, setShowHealthPlanStats] = useState(false);
  const [showPaymentPlanStats, setShowPaymentPlanStats] = useState(false);
  const [showRevenueStats, setShowRevenueStats] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getDashboardInfo = async () => {
      try {
        setIsLoading(true);
        const healthPlanStatsResponse = await http.get(`/report/getHealthPlanStats`);
        setHealthPlanStats(healthPlanStatsResponse.data);

        const paymentPlanStatsResponse = await http.get(`/report/getPaymentPlanStats`);
        setPaymentPlanStats(paymentPlanStatsResponse.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getDashboardInfo();
  }, []);

  return (
    <Page>
      <div className="background-white box-shadow flex-grow-1">
        <div className="p-5">
          <p className="error">{errorMessage}</p>
          {isLoading && <i className="flex-centered fa fa-circle-notch fa-spin fa-2x subtle" />}
          {!isLoading && (
            <React.Fragment>
              <h1 className="mt-4" onClick={() => setShowHealthPlanStats(!showHealthPlanStats)}>
                {showHealthPlanStats && <Icon name="less" />}
                {!showHealthPlanStats && <Icon name="more" />}
                Health Plan Stats
              </h1>
              <Collapse in={showHealthPlanStats}>
                <div>
                  <HealthPlanStats healthPlanStats={healthPlanStats} />
                </div>
              </Collapse>

              <h1 className="mt-4" onClick={() => setShowPaymentPlanStats(!showPaymentPlanStats)}>
                {showPaymentPlanStats && <Icon name="less" />}
                {!showPaymentPlanStats && <Icon name="more" />}
                Payment Plan Stats
              </h1>
              <Collapse in={showPaymentPlanStats}>
                <div>
                  <PaymentPlanStats paymentPlanStats={paymentPlanStats} />
                </div>
              </Collapse>

              <h1 className="mt-4" onClick={() => setShowRevenueStats(!showRevenueStats)}>
                {showRevenueStats && <Icon name="less" />}
                {!showRevenueStats && <Icon name="more" />}
                Revenue Stats
              </h1>
              <Collapse in={showRevenueStats}>
                <div>
                  <RevenueStats />
                </div>
              </Collapse>
            </React.Fragment>
          )}
        </div>
      </div>
    </Page>
  );
}

function HealthPlanStats({ healthPlanStats }) {
  return (
    <div className="p-4">
      <table>
        <thead>
          <tr>
            <th>Category</th>
            <th>Count</th>
            <th>Percentage</th>
            <th>MRR</th>
            <th>MRR (DE Adj.)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Client</td>
            <td>{healthPlanStats.client}</td>
            <td></td>
            <td>{formatCurrency(healthPlanStats.clientMRR)}</td>
            <td>{formatCurrency(healthPlanStats.clientAdjMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">50% Canine</td>
            <td>{healthPlanStats.fiftyCanine}</td>
            <td>{((healthPlanStats.fiftyCanine / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.fiftyCanineMRR)}</td>
            <td>{formatCurrency(healthPlanStats.fiftyCanineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">50% Feline</td>
            <td>{healthPlanStats.fiftyFeline}</td>
            <td>{((healthPlanStats.fiftyFeline / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.fiftyFelineMRR)}</td>
            <td>{formatCurrency(healthPlanStats.fiftyFelineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">90% Canine</td>
            <td>{healthPlanStats.nintyCanine}</td>
            <td>{((healthPlanStats.nintyCanine / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.nintyCanineMRR)}</td>
            <td>{formatCurrency(healthPlanStats.nintyCanineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">90% Feline</td>
            <td>{healthPlanStats.nintyFeline}</td>
            <td>{((healthPlanStats.nintyFeline / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.nintyFelineMRR)}</td>
            <td>{formatCurrency(healthPlanStats.nintyFelineMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">50% DE Canine</td>
            <td>{healthPlanStats.fiftyCanineDE}</td>
            <td>{((healthPlanStats.fiftyCanineDE / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.fiftyCanineDEMRR)}</td>
            <td>{formatCurrency(healthPlanStats.fiftyCanineDEAdjMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">50% DE Feline</td>
            <td>{healthPlanStats.fiftyFelineDE}</td>
            <td>{((healthPlanStats.fiftyFelineDE / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.fiftyFelineDEMRR)}</td>
            <td>{formatCurrency(healthPlanStats.fiftyFelineDEAdjMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">90% DE Canine</td>
            <td>{healthPlanStats.nintyCanineDE}</td>
            <td>{((healthPlanStats.nintyCanineDE / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.nintyCanineDEMRR)}</td>
            <td>{formatCurrency(healthPlanStats.nintyCanineDEAdjMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">90% DE Feline</td>
            <td>{healthPlanStats.nintyFelineDE}</td>
            <td>{((healthPlanStats.nintyFelineDE / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.nintyFelineDEMRR)}</td>
            <td>{formatCurrency(healthPlanStats.nintyFelineDEAdjMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4 pt-4">50%</td>
            <td className="pt-4">{healthPlanStats.fifty}</td>
            <td className="pt-4">
              {((healthPlanStats.fifty / healthPlanStats.client) * 100).toFixed(2)}%
            </td>
            <td className="pt-4">{formatCurrency(healthPlanStats.fiftyMRR)}</td>
            <td className="pt-4">{formatCurrency(healthPlanStats.fiftyAdjMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">90%</td>
            <td>{healthPlanStats.ninty}</td>
            <td>{((healthPlanStats.ninty / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.nintyMRR)}</td>
            <td>{formatCurrency(healthPlanStats.nintyAdjMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4 pt-4">Canine</td>
            <td className="pt-4">{healthPlanStats.canine}</td>
            <td className="pt-4">
              {((healthPlanStats.canine / healthPlanStats.client) * 100).toFixed(2)}%
            </td>
            <td className="pt-4">{formatCurrency(healthPlanStats.canineMRR)}</td>
            <td className="pt-4">{formatCurrency(healthPlanStats.canineAdjMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4">Feline</td>
            <td>{healthPlanStats.feline}</td>
            <td>{((healthPlanStats.feline / healthPlanStats.client) * 100).toFixed(2)}%</td>
            <td>{formatCurrency(healthPlanStats.felineMRR)}</td>
            <td>{formatCurrency(healthPlanStats.felineAdjMRR)}</td>
          </tr>
          <tr>
            <td className="ps-4 pt-4">Plans in 1st Year</td>
            <td className="pt-4">{healthPlanStats.newPlans}</td>
            <td className="pt-4">
              {((healthPlanStats.newPlans / healthPlanStats.client) * 100).toFixed(2)}%
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="pt-4">Employee</td>
            <td className="pt-4">{healthPlanStats.employee}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="pt-4">Total</td>
            <td className="pt-4">{healthPlanStats.total}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div className="mt-4 sura font-14">
        Average Plan Payment: {formatCurrency(healthPlanStats.clientMRR / healthPlanStats.client)}
      </div>
    </div>
  );
}

function PaymentPlanStats({ paymentPlanStats }) {
  return (
    <div className="p-4">
      <div>
        <h2>Total</h2>
        <table>
          <tbody>
            <tr>
              <td className="input-width">Count</td>
              <td>{paymentPlanStats.count}</td>
            </tr>
            <tr>
              <td className="input-width">MRR</td>
              <td>{formatCurrency(paymentPlanStats.MRR)}</td>
            </tr>
            <tr>
              <td className="input-width">MRR added in the last 30 days</td>
              <td>{formatCurrency(paymentPlanStats.newMRR)}</td>
            </tr>
            <tr>
              <td className="input-width">MRR ending in the next 30 days</td>
              <td>{formatCurrency(paymentPlanStats.endingMRR)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-3">
        <h2>Loveland</h2>
        <table>
          <tbody>
            <tr>
              <td className="input-width">Count</td>
              <td>{paymentPlanStats.countLoveland}</td>
            </tr>
            <tr>
              <td className="input-width">MRR</td>
              <td>{formatCurrency(paymentPlanStats.MRRLoveland)}</td>
            </tr>
            <tr>
              <td className="input-width">MRR added in the last 30 days</td>
              <td>{formatCurrency(paymentPlanStats.newMRRLoveland)}</td>
            </tr>
            <tr>
              <td className="input-width">MRR ending in the next 30 days</td>
              <td>{formatCurrency(paymentPlanStats.endingMRRLoveland)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-3">
        <h2>Evans</h2>
        <table>
          <tbody>
            <tr>
              <td className="input-width">Count</td>
              <td>{paymentPlanStats.countEvans}</td>
            </tr>
            <tr>
              <td className="input-width">MRR</td>
              <td>{formatCurrency(paymentPlanStats.MRREvans)}</td>
            </tr>
            <tr>
              <td className="input-width">MRR added in the last 30 days</td>
              <td>{formatCurrency(paymentPlanStats.newMRREvans)}</td>
            </tr>
            <tr>
              <td className="input-width">MRR ending in the next 30 days</td>
              <td>{formatCurrency(paymentPlanStats.endingMRREvans)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function RevenueStats() {
  const [revenueStats, setRevenueStats] = useState(null);

  const [startDate, setStartDate] = useState(
    moment().tz('America/Denver').startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('month').tz('America/Denver').format('YYYY-MM-DD')
  );

  useEffect(() => {
    const getRevenueStats = async () => {
      const response = await http.post(`/report/getRevenueStats`, {
        startDate,
        endDate,
      });
      setRevenueStats(response.data);
    };
    getRevenueStats();
  }, [startDate, endDate]);

  if (!revenueStats) return <div></div>;

  return (
    <div className="p-4">
      <div className="d-flex mt-2">
        <Input
          className="me-2"
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <Input type="date" name="endDate" label="End Date" value={endDate} onChange={setEndDate} />
        <button
          className="mt-2 ms-2 btn-rounded-primary"
          onClick={() => {
            setStartDate(
              moment
                .tz(startDate, 'America/Denver')
                .subtract(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD')
            );
            setEndDate(
              moment
                .tz(endDate, 'America/Denver')
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            );
          }}
        >
          Previous Month
        </button>
        <button
          className="mt-2 ms-2 btn-rounded-primary"
          onClick={() => {
            setStartDate(
              moment
                .tz(startDate, 'America/Denver')
                .add(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD')
            );
            setEndDate(
              moment
                .tz(endDate, 'America/Denver')
                .add(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            );
          }}
        >
          Next Month
        </button>
      </div>

      <div className="mt-4">
        <table className="ms-4">
          <thead>
            <tr>
              <th style={{ width: '200px' }}></th>
              <th style={{ width: '100px' }}>Loveland</th>
              <th style={{ width: '100px' }}>Evans</th>
              <th style={{ width: '100px' }}>Total</th>
            </tr>
          </thead>
        </table>
        <h3 className="mb-2">Completed</h3>
        <table className="ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Doctor hours</td>
              <td style={{ width: '100px' }}>{revenueStats[1].doctorHours}</td>
              <td style={{ width: '100px' }}>{revenueStats[2].doctorHours}</td>
              <td style={{ width: '100px' }}>
                {revenueStats[1].doctorHours + revenueStats[2].doctorHours}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{revenueStats[1].surgeryHours}</td>
              <td>{revenueStats[2].surgeryHours}</td>
              <td>{revenueStats[1].surgeryHours + revenueStats[2].surgeryHours}</td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{revenueStats[1].roomHours}</td>
              <td>{revenueStats[2].roomHours}</td>
              <td>{revenueStats[1].roomHours + revenueStats[2].roomHours}</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Appointments</td>
              <td style={{ width: '100px' }}>{revenueStats[1].appts}</td>
              <td style={{ width: '100px' }}>{revenueStats[2].appts}</td>
              <td style={{ width: '100px' }}>{revenueStats[1].appts + revenueStats[2].appts}</td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{revenueStats[1].surgeryAppts}</td>
              <td>{revenueStats[2].surgeryAppts}</td>
              <td>{revenueStats[1].surgeryAppts + revenueStats[2].surgeryAppts}</td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{revenueStats[1].roomAppts}</td>
              <td>{revenueStats[2].roomAppts}</td>
              <td>{revenueStats[1].roomAppts + revenueStats[2].roomAppts}</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Appointments/Doctor Hour</td>
              <td style={{ width: '100px' }}>{revenueStats[1].apptPerDoctorHour}</td>
              <td style={{ width: '100px' }}>{revenueStats[2].apptPerDoctorHour}</td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <td className="ps-3">Surgery</td>
              <td>{revenueStats[1].sxPerSxHour}</td>
              <td>{revenueStats[2].sxPerSxHour}</td>
              <td></td>
            </tr>
            <tr>
              <td className="ps-3">Rooms</td>
              <td>{revenueStats[1].roomApptsPerRoomHour}</td>
              <td>{revenueStats[2].roomApptsPerRoomHour}</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Retail Value</td>
              <td style={{ width: '100px' }}>{formatCurrency(revenueStats[1].retailValue)}</td>
              <td style={{ width: '100px' }}>{formatCurrency(revenueStats[2].retailValue)}</td>
              <td style={{ width: '100px' }}>
                {formatCurrency(revenueStats[1].retailValue + revenueStats[2].retailValue)}
              </td>
            </tr>
            <tr>
              <td>Total Revenue</td>
              <td>{formatCurrency(revenueStats[1].totalRevenue)}</td>
              <td>{formatCurrency(revenueStats[2].totalRevenue)}</td>
              <td>{formatCurrency(revenueStats[1].totalRevenue + revenueStats[2].totalRevenue)}</td>
            </tr>
            <tr>
              <td className="ps-3">In-Clinic</td>
              <td>{formatCurrency(revenueStats[1].inClinicRevenue)}</td>
              <td>{formatCurrency(revenueStats[2].inClinicRevenue)}</td>
              <td>
                {formatCurrency(revenueStats[1].inClinicRevenue + revenueStats[2].inClinicRevenue)}
              </td>
            </tr>
            <tr>
              <td className="ps-5">Regular Doctor</td>
              <td>{formatCurrency(revenueStats[1].regularInClinicRevenue)}</td>
              <td>{formatCurrency(revenueStats[2].regularInClinicRevenue)}</td>
              <td>
                {formatCurrency(
                  revenueStats[1].regularInClinicRevenue + revenueStats[2].regularInClinicRevenue
                )}
              </td>
            </tr>
            <tr>
              <td className="ps-5">Randy</td>
              <td>{formatCurrency(revenueStats[1].randyRevenue)}</td>
              <td>{formatCurrency(revenueStats[2].randyRevenue)}</td>
              <td>{formatCurrency(revenueStats[1].randyRevenue + revenueStats[2].randyRevenue)}</td>
            </tr>
            <tr>
              <td className="ps-3">Health Plan</td>
              <td>{formatCurrency(revenueStats[1].healthPlanRevenue)}</td>
              <td>{formatCurrency(revenueStats[2].healthPlanRevenue)}</td>
              <td>
                {formatCurrency(
                  revenueStats[1].healthPlanRevenue + revenueStats[2].healthPlanRevenue
                )}
              </td>
            </tr>
            <tr>
              <td className="ps-3">Payment Plan</td>
              <td>{formatCurrency(revenueStats[1].paymentPlanRevenue)}</td>
              <td>{formatCurrency(revenueStats[2].paymentPlanRevenue)}</td>
              <td>
                {formatCurrency(
                  revenueStats[1].paymentPlanRevenue + revenueStats[2].paymentPlanRevenue
                )}
              </td>
            </tr>
            <tr>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Does not include Randy</Tooltip>}
                trigger={['hover']}
              >
                <td>Total Revenue (Adj.)</td>
              </OverlayTrigger>
              <td>{formatCurrency(revenueStats[1].adjTotalRevenue)}</td>
              <td>{formatCurrency(revenueStats[2].adjTotalRevenue)}</td>
              <td>
                {formatCurrency(revenueStats[1].adjTotalRevenue + revenueStats[2].adjTotalRevenue)}
              </td>
            </tr>
            <tr>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    This is the amount we diverted to Payment Plans. It is not included in revenue.
                    Anything we collected on these new payment plans is captured as Payment Plan
                    revenue.
                  </Tooltip>
                }
                trigger={['hover']}
              >
                <td>Paid by Payment Plan</td>
              </OverlayTrigger>
              <td>{formatCurrency(revenueStats[1].paidByPaymentPlan)}</td>
              <td>{formatCurrency(revenueStats[2].paidByPaymentPlan)}</td>
              <td>
                {formatCurrency(
                  revenueStats[1].paidByPaymentPlan + revenueStats[2].paidByPaymentPlan
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Revenue/Doctor Hour</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Revenue collected from Health Plans, Payment Plans, and in clinic. Does not
                    include Randy
                  </Tooltip>
                }
                trigger={['hover']}
              >
                <td className="ps-3">Total</td>
              </OverlayTrigger>
              <td>{formatCurrency(revenueStats[1].totalRevenuePerDoctorHour)}</td>
              <td>{formatCurrency(revenueStats[2].totalRevenuePerDoctorHour)}</td>
              <td></td>
            </tr>
            <tr>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Revenue collected in clinic. Does not include Randy</Tooltip>}
                trigger={['hover']}
              >
                <td className="ps-3">In-Clinic</td>
              </OverlayTrigger>
              <td>{formatCurrency(revenueStats[1].inClinicRevenuePerDoctorHour)}</td>
              <td>{formatCurrency(revenueStats[2].inClinicRevenuePerDoctorHour)}</td>
              <td></td>
            </tr>
            <tr>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    In-Clinic revenue plus revenue that would have been collected without Payment
                    Plans
                  </Tooltip>
                }
                trigger={['hover']}
              >
                <td className="ps-3">In-Clinic (Adj.)</td>
              </OverlayTrigger>
              <td>{formatCurrency(revenueStats[1].adjInClinicRevenuePerDoctorHour)}</td>
              <td>{formatCurrency(revenueStats[2].adjInClinicRevenuePerDoctorHour)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="mt-3 ms-4">
          <tbody>
            <tr>
              <td style={{ width: '200px' }}>Revenue/Appointment</td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
              <td style={{ width: '100px' }}></td>
            </tr>
            <tr>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Revenue collected from Health Plans, Payment Plans, and in clinic. Does not
                    include Randy
                  </Tooltip>
                }
                trigger={['hover']}
              >
                <td className="ps-3">Total</td>
              </OverlayTrigger>
              <td>{formatCurrency(revenueStats[1].totalRevenuePerAppt)}</td>
              <td>{formatCurrency(revenueStats[2].totalRevenuePerAppt)}</td>
              <td></td>
            </tr>
            <tr>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Revenue collected in clinic. Does not include Randy</Tooltip>}
                trigger={['hover']}
              >
                <td className="ps-3">In-Clinic</td>
              </OverlayTrigger>
              <td>{formatCurrency(revenueStats[1].inClinicRevenuePerAppt)}</td>
              <td>{formatCurrency(revenueStats[2].inClinicRevenuePerAppt)}</td>
              <td></td>
            </tr>
            <tr>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    In-Clinic revenue plus revenue that would have been collected without Payment
                    Plans
                  </Tooltip>
                }
                trigger={['hover']}
              >
                <td className="ps-3">In-Clinic (Adj.)</td>
              </OverlayTrigger>
              <td>{formatCurrency(revenueStats[1].adjInClinicRevenuePerAppt)}</td>
              <td>{formatCurrency(revenueStats[2].adjInClinicRevenuePerAppt)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      {moment().tz('America/Denver').isBefore(endDate) && (
        <div className="mt-5">
          <h3 className="mb-1">Projected</h3>
          <table className="ms-4">
            <tbody>
              <tr>
                <td style={{ width: '200px' }}>Doctor hours</td>
                <td style={{ width: '100px' }}>{revenueStats[1].projectedDoctorHours}</td>
                <td style={{ width: '100px' }}>{revenueStats[2].projectedDoctorHours}</td>
                <td style={{ width: '100px' }}>
                  {revenueStats[1].projectedDoctorHours + revenueStats[2].projectedDoctorHours}
                </td>
              </tr>
              <tr>
                <td className="ps-3">Surgery</td>
                <td>{revenueStats[1].projectedSurgeryHours}</td>
                <td>{revenueStats[2].projectedSurgeryHours}</td>
                <td>
                  {revenueStats[1].projectedSurgeryHours + revenueStats[2].projectedSurgeryHours}
                </td>
              </tr>
              <tr>
                <td className="ps-3">Rooms</td>
                <td>{revenueStats[1].projectedRoomHours}</td>
                <td>{revenueStats[2].projectedRoomHours}</td>
                <td>{revenueStats[1].projectedRoomHours + revenueStats[2].projectedRoomHours}</td>
              </tr>
            </tbody>
          </table>

          <table className="mt-3 ms-4">
            <tbody>
              <tr>
                <td style={{ width: '200px' }}>Appointments</td>
                <td style={{ width: '100px' }}>{revenueStats[1].projectedAppts}</td>
                <td style={{ width: '100px' }}>{revenueStats[2].projectedAppts}</td>
                <td style={{ width: '100px' }}>
                  {revenueStats[1].projectedAppts + revenueStats[2].projectedAppts}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="mt-3 ms-4">
            <tbody>
              <tr>
                <td style={{ width: '200px' }}>Total Revenue</td>
                <td style={{ width: '100px' }}>
                  {formatCurrency(revenueStats[1].projectedTotalRevenue)}
                </td>
                <td style={{ width: '100px' }}>
                  {formatCurrency(revenueStats[2].projectedTotalRevenue)}
                </td>
                <td style={{ width: '100px' }}>
                  {formatCurrency(
                    revenueStats[1].projectedTotalRevenue + revenueStats[2].projectedTotalRevenue
                  )}
                </td>
              </tr>
              <tr>
                <td className="ps-3">In-Clinic</td>
                <td>{formatCurrency(revenueStats[1].projectedInClinicRevenue)}</td>
                <td>{formatCurrency(revenueStats[2].projectedInClinicRevenue)}</td>
                <td>
                  {formatCurrency(
                    revenueStats[1].projectedInClinicRevenue +
                      revenueStats[2].projectedInClinicRevenue
                  )}
                </td>
              </tr>
              <tr>
                <td className="ps-3">Health Plan</td>
                <td>{formatCurrency(revenueStats[1].projectedHealthPlanRevenue)}</td>
                <td>{formatCurrency(revenueStats[2].projectedHealthPlanRevenue)}</td>
                <td>
                  {formatCurrency(
                    revenueStats[1].projectedHealthPlanRevenue +
                      revenueStats[2].projectedHealthPlanRevenue
                  )}
                </td>
              </tr>
              <tr>
                <td className="ps-3">Payment Plan</td>
                <td>{formatCurrency(revenueStats[1].projectedPaymentPlanRevenue)}</td>
                <td>{formatCurrency(revenueStats[2].projectedPaymentPlanRevenue)}</td>
                <td>
                  {formatCurrency(
                    revenueStats[1].projectedPaymentPlanRevenue +
                      revenueStats[2].projectedPaymentPlanRevenue
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
