import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';

function DeleteStaff({ staff, handleClose }) {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    await http.delete(`/staff/delete?id=${staff.id}`);
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <p className="sura font-16 fw-bolder">
          Are you sure you want to deactivate {staff.first_name} {staff.last_name}?
        </p>
        <p className="sura font-14 pt-4">
          Staff member account will be deactivated and user will no longer have access to the
          portal.
        </p>
        <ButtonPanel
          primaryIsError={true}
          primaryButtonText="Yes, Deactivate"
          secondaryButtonText="No, Cancel"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          handleErrorMessage={setErrorMessage}
        />
      </form>
    </Modal>
  );
}

export default DeleteStaff;
