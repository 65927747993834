import moment from 'moment-timezone';

export const getRank = (
  blockByRef,
  scheduleColumnId,
  surgeryType,
  blocks,
  isUrgent,
  minDate,
  maxDate,
  prioritizeUpcomingBlocks
) => {
  let rank = null;

  const block = { ...blockByRef };

  if (block.is_hold === 'Y' || !block.id) return -1;

  const within3Days = moment(block.date).isSameOrBefore(moment().add(3, 'days'));

  //look at all remaining surgeries/remaining blocks except for non-urgent surgeries on blocks that are more than 3 days out
  if (isUrgent === 'N' && !within3Days) {
    //adjust remainingSx for blocks w/ urgent blocks so that there will be at least 1 remaining sx available for urgent
    if (Number(block.blocks_urgent) > 0 && block.remainingBlocks > 0 && block.remainingSx > 0) {
      block.remainingSx -= 1;
    }
    //do this after to not mess up remainingSx adjustment
    block.remainingBlocks = block.remainingStandardBlocks;
  }

  //variables for later use
  const { date, remainingBlocks, remainingSx, remainingSterile } = block;
  const within2Days = moment(date).isSameOrBefore(moment().add(2, 'days'));
  const within1Week = moment(date).isSameOrBefore(moment().add(1, 'weeks'));
  const blocksAfterScheduling = remainingBlocks - blocks;
  const isSterile = Number(surgeryType) > 1;
  const availableIfSterile = !isSterile || (isSterile && remainingSterile > 0);

  //freebie neuter
  if (
    Number(surgeryType) === 3 &&
    blocks <= 1 &&
    Number(block.blocks) === 6 &&
    block.max_surgeries - remainingSx === 2 &&
    (remainingBlocks === 0.5 || (remainingBlocks === 0 && block.max_sterile === remainingSterile))
  ) {
    return 0.1;
  }

  //throw out if block has no remaining blocks
  if (remainingBlocks <= 0) return -1;
  //throw out if block has no remaining surgeries
  if (remainingSx <= 0) return -1;
  //throw out if block does not have enough remaining blocks to fit surgery
  if (blocks && remainingBlocks < blocks) return -1;
  //throw out if surgery is sterile and block does not a remaining sterile surgery
  if (isSterile && block.remainingSterileAdj <= 0) return -1;
  //throw out if block does not match preferred doctor
  if (scheduleColumnId && Number(block.schedule_column_id) !== Number(scheduleColumnId)) return -1;
  //throw out if block does not fall within date preferences
  if (minDate && date.localeCompare(minDate) < 0) return -1;
  if (maxDate && date.localeCompare(maxDate) > 0) return -1;

  //prioritize filling openings w/in 1 week
  if (prioritizeUpcomingBlocks && within1Week && blocksAfterScheduling >= 0 && availableIfSterile) {
    if (within2Days) {
      //prefer to fill w/in 1 block w/in 2 days over any other option
      if (blocksAfterScheduling === 0) {
        rank = 0.1;
      } else if (blocksAfterScheduling === 0.5) {
        rank = 0.2;
      } else if (blocksAfterScheduling === 1) {
        rank = 0.3;
      } //prefer to fill w/in .5 blocks w/in 1 week over leaving 1.5 extra blocks w/in 2 days
      else if (blocksAfterScheduling === 1.5) {
        rank = 0.6;
      }
      //fill w/in 1 block w/in 1 week
    } else {
      if (blocksAfterScheduling === 0) {
        rank = 0.4;
      } else if (blocksAfterScheduling === 0.5) {
        rank = 0.5;
      } else if (blocksAfterScheduling === 1) {
        rank = 0.6;
      }
    }
    if (rank) return rank;
  }

  //fill all remaining blocks and is final remaining surgery
  if (
    remainingSx === 1 &&
    blocksAfterScheduling === 0 &&
    (!isSterile || (isSterile && remainingSterile > 0))
  ) {
    return 1;
  }

  //fill all remaining blocks
  if (blocksAfterScheduling === 0 && (!isSterile || (isSterile && remainingSterile > 0))) {
    rank = 2;
    //downrank filling w/ 2 surgeries if 3 max (2 large sx not good use of techs)
    if (Number(block.max_surgeries) - remainingSx === 1) {
      rank = 11;
    }
    return rank;
  }

  if (remainingSx > 1) {
    if ((!isSterile && remainingSterile > 0) || (isSterile && remainingSterile > 1)) {
      //set up for 1 blk sterile
      if (blocksAfterScheduling === 1) return 3;
      //set up for 2 blk sterile
      if (blocksAfterScheduling === 2) return 4;
      //set up for 3 blk sterile
      if (blocksAfterScheduling === 3) return 5;
    }

    if (!isSterile || (isSterile && remainingSterile > 0)) {
      //set up for 2 blk non-sterile
      if (blocksAfterScheduling === 2) return 6;
      //set up for 3 blk non-sterile
      if (remainingSx > 1 && blocksAfterScheduling === 3) return 7;
    }
  }

  //downrank situations where scheduling this surgery makes it impossible/unlikely to fill blocks
  if (
    remainingSx === 1 ||
    (remainingSx === 2 && blocksAfterScheduling > 3) ||
    blocksAfterScheduling === 0.5 ||
    //don't want to depend on a non-sterile 1 or 1.5 blk (very rare)
    (blocksAfterScheduling <= 1.5 &&
      ((!isSterile && remainingSterile === 0) || (isSterile && remainingSterile === 1)))
  ) {
    return -1;
  }

  return 10;
};
